@charset "utf-8";
@import "./vars.scss";

header,
section,
footer,
aside,
nav,
main,
article,
figure {
  display: block;
}

div,
header,
nav,
article,
section,
aside,
footer,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li,
figure,
figcaption,
class,
img {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  background-color: $light-bg;
  border: 0 solid transparent;
  font-size: 16px;
  margin: 0;
  min-height: 100%;
  padding: 0;
  width: 100%;
  display: block;
  font-family: proximaReg;
}

html {
  overflow-x: hidden;
}
img,
object,
embed,
canvas,
video,
audio,
picture,
svg {
  max-width: 100%;
  height: auto;
}
img {
  display: block;
}
a {
  text-decoration: none;
  color: $black;
}
ul,
ol {
  font-size: 0;
  list-style-type: none;
  li {
    font-size: 1rem;
  }
}
button {
  font-size: 1rem;
  background: none;
  border: none;
}
strong {
  font-family: proximaBold;
}

/* ------------------------------- RECURENTES -- */
.clear {
  display: block;
  zoom: 1;
  &:after {
    content: " ";
    display: block;
    font-size: 0;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

.center {
  width: 1024px;
  margin: 0 auto;
}

.noScroll {
  overflow: hidden;
}

.commandLineSkeleton {
  height: 45px;
  margin-bottom: 20px;
  border-radius: 8px;
}

/* ------------------------ MEDIA QUERIES 960px ---- */

@media screen and (max-width: 1024px) {
  .center {
    width: 100%;
    margin: 0;
  }
}
