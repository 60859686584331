/* App Fonts */
@font-face {
  font-family: "proximaReg";
  src: url("./font/Proxima-Nova-Alt-Regular.otf");
}

@font-face {
  font-family: "proximaThin";
  src: url("./font/Proxima-Nova-Alt-Thin.otf");
}

@font-face {
  font-family: "proximaLight";
  src: url("./font/Proxima-Nova-Alt-Light.otf");
}

@font-face {
  font-family: "proximaBold";
  src: url("./font/Proxima-Nova-Alt-Bold.otf");
}

/* App Colors */
$black: black;
$black-overlay: rgba(0, 0, 0, 0.6);
$white: white;
$light-gray: #979aa1;
$lighter-gray: #f4f6f7;
$lightest-gray: #eeeeee;
$light-bg: #f9f9fb;
$brand-green: #4addb4;
$red: #f92127;
$pale-violet: #b7b7c9;

/* Commands Status Color Code */
$newCommandBg: rgba(74, 221, 180, 0.15);
$newCommand: #4addb4;

$pendingCommandBg: rgba(221, 146, 74, 0.15);
$pendingCommand: #ff8625;

$validatedCommandBg: rgba(24, 120, 243, 0.12);
$validatedCommand: #1878f3;

$canceledCommandBg: rgba(249, 35, 75, 0.11);
$canceledCommand: #f9234b;

$confirmCommandBg: #b7b7c9;

/* Box Shadow */
$menu-box-shadow: 0 12px 40px rgba(0, 0, 0, 0.05);
$button-box-shadow: 0 6px 7px rgba(0, 0, 0, 0.11);
$container-box-shadow: 0 12px 34px rgba(0, 0, 0, 0.1);
